import axios from "axios";
import store from "../redux/store";
import { SERVER_URL } from "../config/index";

const request = axios.create({
  baseURL: SERVER_URL,
  timeout: 1000000,
});
let requests = [];
request.interceptors.request.use(
  (config) => {
    // do something before request is sent
    if (store.getState().user?.token) {
      config.headers.Authorization = `Bearer ${store.getState().user?.token}`;
    }
    requests.push(config);
    return config;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);

/* Auth API */
// Login
export const adminLogin = (data) => {
  return new Promise(async (resolve, reject) => {
    await axios
      .post(SERVER_URL + "auth/login", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// Register
export const adminRegister = (data) => {
  return new Promise(async (resolve, reject) => {
    await axios
      .post(SERVER_URL + "auth/register", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

/* Users API */
// Get Product List
export const getUsersList = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`/user`, { params: data })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Get Product Details By Id
export const getUsersDetailsById = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`user/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Update Product Details By Id
export const editUsersDetailsById = (id, body) => {
  return new Promise(async (resolve, reject) => {
    await request
      .put(`user/` + id, body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Remove Product Details By Id
export const deleteUsers = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .delete(`user/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

/* Contact Us */
// Get Contact Us List
export const getContactUsList = (data, token) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get("contact-us", { params: data })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Delete Contact Us Details By Id
export const deleteContactUsDetailsById = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .delete(`/contact-us/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

/* Career */
// Get Career  List
export const getCareerList = (data, token) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get("career", { params: data })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Delete Career Details By Id
export const deleteCareerDetailsById = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .delete(`/career/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

/* News Letter */
// Get News Letter List
export const getNewsLetterList = (data, token) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get("news-letter", { params: data })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Delete News Letter Details By Id
export const deleteNewsLetterDetailsById = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .delete(`/news-letter/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
