import { Link } from "react-router-dom";

/* SAMPLE DATA */
import navMenu from "../../data/navigation";

/* ASSETS */
import Logo from "../../assets/images/white-logo.png";

const Sidebar = () => {
  var pathname = window.location.pathname;

  return (
    <section className="h-sidebar w-sidebar bg-[#141518] transition-all duration-300 ease-linear rounded-xl shadow-xl">
      {/* HEADER */}
      <div className="h-header border-b border-white border-opacity-20 px-5 flex items-center text-center">
        <img
          src={Logo}
          className=" w-28 h-auto object-contain text-center mx-auto"
          alt="Advanced Electrical Solutions"
        />
      </div>

      {/* NAVIGATION OPTIONS */}
      <div className="h-[90vh] flex flex-col justify-between">
        <div className="p-5">
          <div className="mt-0">
            {navMenu.map((nav) => (
              <Link
                to={nav.link}
                key={nav.id}
                className="w-full group flex items-center gap-3 first:mt-0 mt-5"
              >
                <nav.icon
                  className={`w-5 h-5 text-sidebarText ${pathname ===
                    nav.link &&
                    "text-white"} group-hover:text-white transition-all duration-300 ease-in-out`}
                />
                <div>
                  <p
                    className={`text-base font-proxima-semibold text-sidebarText ${pathname ===
                      nav.link &&
                      "text-white"} group-hover:text-white leading-none transition-all duration-300 ease-in-out`}
                  >
                    {nav.name}
                  </p>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Sidebar;
